jQuery(window).on("load", function () {

});

$(document).ready(function () {

    $("#navToggle").click(function () {
        $(this).toggleClass("active");
        $(".overlay").toggleClass("open");
        // this line ▼ prevents content scroll-behind
        $("body").toggleClass("locked");
    });

    $(".go").on("click", function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({
            scrollTop: top
        }, 1000);
    });

    $(".payButton").on("click", function (event) {
        $('.modal').modal('hide');
    });

    $(".btn-call ").on("click", function (event) {
        $('.puls').toggleClass('active2');
        $(this).toggleClass('active');
    });


    $(document).mouseup(function (e) { // событие клика по веб-документу
        if ($('.puls').hasClass('active2')) {
            var div = $(".puls"); // тут указываем ID элемента
            if (!div.is(e.target) // если клик был не по нашему блоку
                &&
                div.has(e.target).length === 0) { // и не по его дочерним элементам
                div.hide(); // скрываем его
            }
        }
    });




    $('.inputPhone').mask('(099) 999-99-99');

    $(function () {
        $('form').submit(function (e) {
            var $form = $(this);
            $.ajax({
                type: "POST",
                url: 'http://veka.kh.ua/mailer/telegram.php',
                data: $form.serialize()
            }).done(function () {
                console.log('success');
                $('.modal').modal('hide')
                $('.puls').hide();
                $(this).find("input").val("");
                $("form").trigger("reset");
                $('#thank').modal('show');
                setTimeout(function () {
                    $('#thank').modal('hide')
                }, 4000)
            }).fail(function () {
                console.log('fail');
                $(this).find("input").val("");
                $("form").trigger("reset");
            });
            //отмена действия по умолчанию для кнопки submit
            e.preventDefault();
        });
    });

    /*$('form').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: "POST",
            url: 'https://edikshovkovyi.ru/budwiks/mailer/telegram.php',
            data: $(this).serialize()
        }).done(function () {
            console.log('123');
            
            $('#thank').modal('show')
            $(this).find("input").val("");
            $("form").trigger("reset");
        });
        return false;
    });*/


    /*$(".link-w a").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });

    var target = $('#demo');
    var targetPos = target.offset().top;
    var winHeight = $(window).height();
    var scrollToElem = targetPos - winHeight;
    $(window).scroll(function () {
        var winScrollTop = $(this).scrollTop();
        if (winScrollTop > scrollToElem) {
            $('.circlechart').show();
        }
    });

    $("#menu").mmenu({
        "extensions": [
                "position-right",
                "pagedim-black",
                "border-offset",
                "shadow-page"
             ]
    });

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.ham').removeClass('active');
    });

    $(".my-progress-bar").circularProgress({
        line_width: 6,
        color: "#765",
        starting_position: 0, // 12.00 o' clock position, 25 stands for 3.00 o'clock (clock-wise)
        percent: 0, // percent starts from
        width: "240px",
        height: "240px",
        percentage: true,
        text: "Increased energy efficiency"
    }).circularProgress('animate', 34, 3000);



    $('.el').circleProgress({
        startAngle: -1.5,
        fill: {
            gradient: ['#AC6FE6', '#6D95E7']
        }
    }).on('circle-animation-progress', function (event, progress) {
        $(this).find('strong').html(parseInt(100 * progress) + '<i>%</i>');
    });*/






    /*
        

        $(".form-input input").focus(function () {
            $(this).parent().find('label').addClass('focus');
        });

        $(".form-input input").blur(function () {
            if ($(this).val() == "") {
                $(this).parent().find('label').removeClass('focus');
            }
        });
        
        
        
        $('.dropdown-item').click(function () {
            var status = $(this).attr('data-status');
            $(this).parent().parent().find('.dropdown-toggle').text(status);
            $('.dropdown-item').removeClass('active');
            $(this).addClass('active');
        })
        
        var heigthFirstScreen = $('.main').height();
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > heigthFirstScreen) {
                $('header nav').addClass('fixed');
            } else if (st > 100) {
                $('header nav').addClass('top');
            } else {
                $('header nav').removeClass('top');
            }
            if ($(window).scrollTop() == 0) {
                $('header nav').removeClass('fixed');
            }

            lastScrollTop = st;
        });
        
        
        $('.main-form').validate({
            submitHandler: function (form) {
                
            }
        });
    */

});
